select.form-control {
  color: #000000
}

.activity-filter {
  width: 150px;
  padding: 0px 12px;
  margin: 8px;
}

.activity-filter .input {
  border: none;
}