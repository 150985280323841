@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
      width: 100%;
      height: 297mm;
      font-family: 'THSarabunNewRegular';
  }
  .a4 {
    height: 29.7cm !important;
  }

  .page {
      page-break-after: always;
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'THSarabunNewRegular';
}

.page {
  width: 210mm;
  height: 297mm;
  margin: 5rem;
  page-break-after: always;
  word-wrap: break-word;
  font-family: 'THSarabunNewRegular';
}

.defect-list {
  margin: 0.5rem 2rem 3rem 2rem;
  width: 100%;
}

.header-table {
  text-align: left;
  font-weight: 100;
  font-size: 1rem;
  padding: 1rem 0;
}

.signature {
  margin-top: 2rem;
  text-align: right;
}

.signature-session {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.order {
  text-align: center;
}

.image {
  height: 80px;
}

.logo-session {
  text-align: center;
  margin-bottom: 32px;
}

.logo {
  height: 100px;
}

.a4 {
  width: 21cm;
  page-break-before: always;
  padding: 1cm 1.25cm 1cm 1cm;
  font-family: 'THSarabunNewRegular'
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5mm; 
}

.footer .footer-text {
  text-align: left
}

.print-section{
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.height-100 {
  height: 100%;
}