.executive-dashboard {
  padding: 16px 0 0 16px;
}

.dashboard-summary-container,
.dashboard-status-container {
  display: flex;
  justify-content: space-between;
}

.dashboard-status-container {
  margin-top: 16px;
}

.Dashboard__card {
  border-radius: 8px;
  padding: 20px 24px;
  width: 32%;
  height: 200px;
  text-align: left;
  color: #ffffff;
  margin: 16px;
}

.Dashboard__status-card {
  outline: none;
  border: none;
  width: 100%;
  height: 150px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
  text-align: left;
  padding: 16px;
  background: #ffffff;
  margin-bottom: 16px;
}

.Dashboard__card.overall {
  background: #00ace1;
}

.Dashboard__card.sold {
  background: #00b777;
}

.Dashboard__card.available {
  background: #ffb400;
}

.Dashboard__card.outcome .Dashboard__card--icon {
  transform: rotate(180deg) scaleX(-1);
}

.Dashboard__card--title, .Dashboard__card--money, .Dashboard__card--analytic {
  margin: 0;
}

.Dashboard__card--link {
  color: #00ace1;
  padding: 24px 0;
}

.summary-text {
  color: #ffffff;
}

.summary-icon {
  margin-bottom: 8px;
}

.header-title-section {
  margin-top: 32px;
  display: flex;
}

.ranking-section {
  margin: 16px;
  display: flex;
  justify-content: space-between;
}

.ranking-section-for-walk {
  margin: 16px;
  display: flex;
  border-bottom: 1px solid #eaeae5;
  justify-content: space-between;
}

.walk-and-sold-detail,
.transfer-detail,
.offline-cost-detail,
.online-cost-detail {
  display: flex;
  justify-content: space-evenly;
}

.transfer-detail {
  border-bottom: 1px solid #eaeae5;
  padding-bottom: 8px;
}

.graph-filter {
  width: 150px;
  padding: 0 0 0 8px;
}

.graph-filter .input {
  border: none;
}