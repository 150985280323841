/* body{
  font-family: 'Taviraj', Arial, Helvetica, sans-serif !important;
} */
.h1,.h2,.h3,.h4,.h5,.h6{
  margin: 0.25rem 0 ;
  font-family: Arial, 'Taviraj', Helvetica, sans-serif !important;
}
.a4 .h1{
  font-size: 24px !important;
  font-weight: 600;
}
.a4 .h1::first-letter {
    margin-top: 0  !important;
}
.a4 .h2{
  font-size: 20px !important;
  font-weight: 600;
}
.a4 .h3{
  font-size: 18px !important;
  font-weight: 600;
}
.a4 p{
  font-size: 16px;
  font-family: Arial, 'Taviraj', Helvetica, sans-serif !important;
  font-weight: 300;
}
.a4 strong{
    font-weight: 400;
}
.a4{
  width: 21cm;
  height: 29.7cm;
  padding: 1cm 1.25cm 1cm 1cm;
  page-break-before: always;
  /* padding: 5mm ;  */
  /* border: 1px solid black; */
}

@media print{
    .a4{
        /* width: 21cm;
        height: 29.7cm; */
        /* size: 210mm 297mm; */
        /* margin: 25mm;
        margin-right: 45mm; */
        zoom: 133%;
    }
}
.print-container{
  height: 100%;
  width: 100%;
  /* padding: 1cm; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12mm;
}
.header-info{
  width: 15cm;
  font-family: Arial, 'Taviraj', Helvetica, sans-serif !important;
}

.content-section{
  text-align: center;
}
.defect-header{
  margin-bottom: 1rem;
}
.defect-table{
  border-spacing: 0px !important;
  border: 1px solid grey;
  width:100%;
}
.defect-info {
  padding: 5mm;
  width: 50%;
  text-align: center;
  border-bottom: 1px solid grey;
}
.defect-info.before {
  border-right: 1px solid grey;
}
.defect-picture{
  /* width: auto; */
  width: auto;
  height: 4.5cm;
  margin-bottom: 0.75rem;
}
.header{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.summary-content{
  text-align: left;
  margin-bottom: 1cm;
  margin-top: 1cm;
}
.summary-content p{
    padding-right: 5mm;
}
.summary-signature{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10.5cm;
}
.signature{
  text-align: left;
  width: auto;
}
.signature img{
  width: 4cm;
  height: 1cm;
  /* padding: 0 0 2mm 0; */
  border:0;
  border-bottom: 1px solid black;
  border-style: dotted;
}
.signature p{
  width: auto; 
}
.signature-name{
  width: 4.3cm !important;
  margin-left: 0.9cm;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.signature-name strong{
  font-size: 13px;
}
.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5mm; 
}
.footer-text{
  width: 100%;
  padding: 0 0 0 5mm;
  margin: 0;
  font-size: 12px !important;
  color: rgb(44, 44, 44)
}
.page-num {
  width: 100%;
  text-align: right;
  margin: 0 !important;
  padding: 0 1cm 0 0;
  font-size: 12px !important;
  color: rgb(44, 44, 44)
}
.logo-infinite{
  width: auto;
  margin: 0 0 5mm 0;
  height: 2cm;
}
.logo-project{
  width: auto;
  height: 2cm;
}
.footer .logo-project{
  padding: 1mm 5mm 2mm;
  border: 0;
  border-right: 1px solid #636463;
  height: 1.5cm;
}
.header-section .logo-project{
  /* margin-top: 5mm; */
  margin-right: 5mm;
}

