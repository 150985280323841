.Release-note-page__container {
  margin: 24px auto;
  max-width: 976px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding: 24px;
}

h1.title {
  padding-top: 16px;
  font-weight: 400;
}

h2.title {
  font-weight: 400;
  margin: 16px 0 0 10px;
}

.billme-logo {
  width: 160px;
  height: 120px;
}

.current-version {
  margin: 0;
  color: #cecece;
  font-size: 18px;
}

.release-card .title {
  color: #00ace1;
  margin: 0;
  font-size: 28px;
}

.version-area {
  width: 400px;
}

.detail-area {
  width: 100%;
}

.release-date {
  margin: 16px 0 0 4px;
  color: #8e8e8e;
  font-size: 16px;
}

.Release-note-page__note {
  margin-top: 32px;
  padding: 8px 0;
}

.Release-note-page .release-card {
  display: flex;
  padding: 24px 0;
}

.detail-card .title {
  margin: 0;
  color: #404040;
  font-size: 28px;
}

.detail-list {
  display: block;
  list-style-type: circle;
  color: #00ace1;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 12px;
  padding-inline-start: 20px;
}

.detail-list .item span {
  color: #404040;
}

.powered-by {
  font-size: 18px;
  color: #e5e5ea;
  text-align: center;
  margin: 0 0 24px 0;
}