.Dashboard__status-sub-card{
  outline: none;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
  text-align: left;
  padding: 14px 12px;
  background: #ffffff;
  width: 100%;
  margin: 16px 0;
}

.Dashboard__status-sub-card-title,
.Dashboard__status-sub-card-data,
.Dashboard__status-sub-card-note {
  margin: 0;
}

.Dashboard__status-sub-card-note {
  color: #C8CCD1
}